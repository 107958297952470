.logomyza1 {
  width: 100%;
  margin-bottom: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2e2f3e;
  padding-top: 80px;
  @media (max-width: 768px) {
    padding-top: 0px;
  }
  .carousellogo1 {
    width: 80%;
    .slick-slider {
      width: 100%;
      .slick-next::before,
      .slick-prev::before {
        font-family: "slick";
        font-size: 30px;
      }
      .slick-list {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        @media (max-width: 768px) {
          border-radius: 0px;
        }
      }
      .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
      }

      .slick-dots {
        position: absolute; /* Make dots absolute for positioning within slides */
        bottom: 10px; /* Adjust vertical positioning */
        left: 50%; /* Center dots horizontally */
        transform: translateX(-50%); /* Center dots precisely */
        z-index: 1; /* Ensure dots are above image content */
      }
      .slick-prev {
        z-index: 1;
      }
      .slick-dots {
        li {
          margin: 0;
        }
      }
    }
  }
}
