.logoadora {
  width: 100%;
  background-color: #2e2f3e;
  img {
    padding-top: 20px;
    width: 28%;
    @media (max-width: 768px) {
      width: 60%;
      padding-top: 0px;
    }
  }
}
