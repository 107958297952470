@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.logo {
  width: 100%;
  .container-footer {
    width: 100%;
    display: flex;
    margin-top: 80px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: -10px;
    background-color: #f6f6f6;
    .container-atas {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin-bottom: -10px;
      z-index: 50;
      @media (max-width: 768px) {
        flex-direction: column;
        width: 100%;
        margin: 0px;
      }
      .container-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        padding: 20px;
        @media (max-width: 768px) {
          width: 50%;
          margin-bottom: -20px;
        }
        .gambar-logo {
          width: 80%;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
      }
      .container-deskripsi {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 60%;
        padding: 20px;
        @media (max-width: 768px) {
          width: 80%;
        }
        .alamat {
          text-align: center;
          font-weight: 400;
          font-family: "Poppins";
          font-size: 14px;
          h2 {
            color: #545454;
            font-weight: 400;
            font-size: 28px;
            margin-bottom: -20px;
            @media (max-width: 768px) {
              font-size: 24px;
            }
          }
          h1 {
            font-size: 32px;
            color: #15283b;
            margin-bottom: -20px;
            @media (max-width: 768px) {
              font-size: 23px;
            }
          }
        }
        .contact {
          text-align: center;
          font-weight: 400;
          font-family: "Poppins";
          font-size: 12px;
        }
        //    .privasi{

        //  }
      }
    }
  }
  .container-bawah {
    width: 100%;
    height: 20vh;
    background-image: url(./footer.webp);
    background-size: cover;
    @media (max-width: 768px) {
      height: 15vh;
      background-position: center;
    }
  }
}
