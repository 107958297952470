.heroc-home {
  width: 100%;
  .container-heroc-home {
    width: 100%;
    height: 80vh;
    background-image: url(../../asset/hero/home.webp);
    background-size: cover;
    @media (max-width: 768px) {
      width: 100%;
      height: 80vh;
      background-image: url(../../asset/hero/mobile-homehero.webp);
    }
  }
}
