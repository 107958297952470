.overlay {
  text-decoration: none;
  color: black;
}
.card {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 95%;
  }
  .container-card {
    width: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background-color: white;
    font-family: "Poppins";
    &:hover {
      box-shadow: 0px 0px 10px 0px rgba(58, 58, 58, 0.43);
      cursor: pointer;
    }
    @media (max-width: 768px) {
      width: 100%;
    }

    .atas {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      gap: 10px;
      font-weight: 600;
      .promos {
        width: 50%;
        font-size: 20px;
        @media (max-width: 768px) {
          font-size: 25px;
          padding: 0px;
        }
      }
      .detail {
        width: 50%;
        font-weight: 600;

        .start {
          font-size: 16px;
          margin-bottom: -5px;
        }
        .angka {
          font-size: 50px;
        }
        .ket {
          font-size: 18px;
          margin-top: -10px;
        }
      }
    }
    .bawah {
      display: flex;
      border-width: 3px 0 3px 0;
      border-style: solid;
      border-radius: 10px;
      border-color: #ffa532;
      margin-top: 5px;
      margin-bottom: 10px;
      .logos1 {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        img {
          width: 100%;
        }
      }
    }
  }
}
