.devider {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .a {
    border-radius: 10px;
    width: 50%;
    text-align: center;
    background-color: #2e2f3e;
    color: white;
    font-size: 65px;
  }
}
