.lokasi {
  width: 100%;
  margin-top: 3%;
  text-align: left;
  .container-lokasi {
    width: 100%;
    .header-lokasi {
      font-size: 55px;
      font-weight: 600;
      color: #33375e;
      text-align: center;
    }
    .content {
      display: flex;
      margin-top: 30px;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .maps {
        width: 70%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
          width: 100%;
          flex-direction: column;
          overflow: hidden;
        }
        .img-lokasi-dekstop {
          width: 100%;
          height: 400px;
          object-fit: cover;
          @media (max-width: 768px) {
            visibility: hidden;
            width: 0%;
            height: 0%;
          }
        }
        .img-lokasi-mobile {
          visibility: hidden;
          width: 0%;
          border-radius: 10px;
          @media (max-width: 768px) {
            visibility: visible;
            width: 98%;
            height: 100%;
          }
        }
      }
      .ket {
        width: 35%;
        display: flex;
        background-color: #2e2f3e;
        justify-content: center;
        align-items: center;
        text-align: left;
        @media (max-width: 768px) {
          width: 100%;
        }
        .angka {
          font-size: 30px;
          font-weight: 600;
          color: white;
        }
        .desk {
          color: #f27d23;
        }
        .detail1 {
          padding: 10px 0px;
        }
        .detail2 {
          padding: 10px 0px;
        }
        .detail3 {
          padding: 10px 0px;
        }
        .point1 {
          width: 40%;
        }
        .point2 {
          width: 35%;
        }
      }
    }
  }
  .benefit-container {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    justify-content: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .benefit-point {
      color: #33375e;
      padding-right: 45px;
      @media (max-width: 768px) {
        flex-direction: column;
        padding-right: 0;
        padding-left: 25px;
      }
      .benefit-title {
        font-family: "Robbotto", Sans-serif;
        font-size: 40px;
        font-weight: 600;
        font-size: 30px;
        padding: 20px 30px 30px 0;
      }
      .benefit {
        font-size: 16px;
      }
    }
  }
}
