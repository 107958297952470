.devbsd {
  margin-top: 3%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .container-devbsd {
    width: 80%;
    margin-bottom: 2%;
    @media (max-width: 768px) {
      width: 98%;
    }
    .header-devbsd {
      font-size: 45px;
      font-weight: 600;
      color: #33375e;
    }
    .carousel {
      .slick-slider {
        width: 100%;
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }
        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */
          bottom: 10px; /* Adjust vertical positioning */
          left: 50%; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 30px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
      }
    }
  }
  .dividera {
    width: 100%;
    background-color: #ffa532;
    height: 100px;
    overflow: hidden;
    position: relative;
    &:before {
      content: "";
      font-family: "shape divider from ShapeDividers.com";
      position: absolute;
      bottom: -1px;
      left: -1px;
      right: -1px;
      top: -1px;
      z-index: 3;
      pointer-events: none;
      background-repeat: no-repeat;
      background-size: 100% 92px;
      background-position: 50% 0%;
      background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23ffffff"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23ffffff"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23ffffff"/></svg>');
    }

    @media (min-width: 768px) {
      .shapedividers_com-5429::before {
        background-size: 100% 92px;
        background-position: 50% 0%;
      }
    }

    @media (min-width: 1025px) {
      .shapedividers_com-5429::before {
        bottom: -0.1vw;
        left: -0.1vw;
        right: -0.1vw;
        top: -0.1vw;
        background-size: 100% 180px;
        background-position: 50% 0%;
        background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23ffffff"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23ffffff"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23ffffff"/></svg>');
      }
    }
    @media (min-width: 2100px) {
      .shapedividers_com-5429::before {
        background-size: 100% calc(2vw + 180px);
      }
    }
  }
}
