.residentials {
  width: 100%;
  margin-top: 150px;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
  .container-residentials {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;
    .containerheader-residentials {
      width: 80%;
      @media (max-width: 768px) {
        width: 90%;
      }
      .navigasi {
        display: flex;
        gap: 20px;

        .link {
          text-decoration: none;
          color: rgb(91, 91, 91);
        }
      }
      .h1 {
        margin-top: 20px;
        text-align: left;
        font-size: 30px;
        font-weight: 700;
        color: #2e2f3e;
      }
    }
    .dividera {
      width: 100%;
      background-color: rgba(51, 51, 51, 0.397);
      height: 100px;
      overflow: hidden;
      position: relative;
      &:before {
        content: "";
        font-family: "shape divider from ShapeDividers.com";
        position: absolute;
        bottom: -1px;
        left: -1px;
        right: -1px;
        top: -1px;
        z-index: 3;
        pointer-events: none;
        background-repeat: no-repeat;
        background-size: 100% 92px;
        background-position: 50% 0%;
        background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23ffffff"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23ffffff"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23ffffff"/></svg>');
      }

      @media (min-width: 768px) {
        .shapedividers_com-5429::before {
          background-size: 100% 92px;
          background-position: 50% 0%;
        }
      }

      @media (min-width: 1025px) {
        .shapedividers_com-5429::before {
          bottom: -0.1vw;
          left: -0.1vw;
          right: -0.1vw;
          top: -0.1vw;
          background-size: 100% 180px;
          background-position: 50% 0%;
          background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="%23ffffff"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="%23ffffff"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="%23ffffff"/></svg>');
        }
      }
      @media (min-width: 2100px) {
        .shapedividers_com-5429::before {
          background-size: 100% calc(2vw + 180px);
        }
      }
    }
    .a {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: rgba(51, 51, 51, 0.397);
      .container-cardproduk {
        width: 100%;
        padding: 20px 0px;

        display: grid;
        grid-template-columns: auto auto auto;
        gap: 30px;
        justify-content: center;
        @media (max-width: 768px) {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
        }
      }
      .buttonr {
        padding-top: 2%;
        padding-bottom: 2%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        @media (max-width: 768px) {
          width: 100%;
          justify-content: center;
          align-items: center;
        }
        .buttonwa2 {
          .whatsapp {
            width: 100%;
            background-color: #33375e;
            color: white;
            padding: 12px 75px;
            border-radius: 5px;
            border-width: 3px;
            border-color: #33375e;
            &:hover {
              border-width: 3px;
              border-color: #33375e;
              color: #33375e;
              background-color: white;
              transition: 0.3s;
              cursor: pointer;
            }
          }
        }
      }
      .carousel-produk {
        box-shadow: 0 0 10px 0;
        width: 70%;
        .slick-slider {
          width: 100%;
          .slick-next::before,
          .slick-prev::before {
            font-family: "slick";
            font-size: 30px;
          }
          .slick-track {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
          }
          .slick-dots {
            position: absolute; /* Make dots absolute for positioning within slides */
            bottom: 10px; /* Adjust vertical positioning */
            left: 50%; /* Center dots horizontally */
            transform: translateX(-50%); /* Center dots precisely */
            z-index: 1; /* Ensure dots are above image content */
          }
          .slick-next {
            right: 25px;
          }
          .slick-prev {
            z-index: 1;
            left: 30px;
          }
          .slick-dots {
            li {
              margin: 0;
            }
          }
        }
      }
    }
  }
}
