.cardprod {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(138, 200, 225, 1) 100%
  );
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .imgcard {
    width: 70%;
    box-shadow: 0 0 10px 0;
    .slick-dots {
      bottom: 15px;
      li {
        margin: 0;
      }
    }
    .slick-next {
      right: 25px;
    }
    .slick-prev {
      z-index: 1;
      left: 25px;
    }
    .slick-slide {
      width: 100%;
      .image {
        height: auto;
        object-fit: cover;
        @media (max-width: 768px) {
          height: auto;
        }
      }
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .cardcontent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
    @media (max-width: 768px) {
      width: 100%;
    }
    .det {
      width: 80%;
      text-align: left;
      @media (max-width: 768px) {
        width: 90%;
      }
      .cardtitle {
        font-size: 50px;
        color: #9c825b;
        font-family: "Playfair Display", Sans-serif;
        font-weight: 600;
        padding: 15px 0 15px 0;
      }
      .cardsub {
        font-size: 30px;
        color: #2b2c35;
        font-weight: 700;
        font-family: "Poppins", sans-serif;
        padding: 0px 0 5px 0;
      }
      .cardicon {
        color: #2b2c35;
        width: 100%;
        font-size: 25px;
        font-weight: 300;
        font-family: "Poppins", sans-serif;
        padding: 15px 25px 5px 0;
      }
    }

    .cardprice {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: #2b2c35;
      border-radius: 5px;
      font-size: 22px;
      font-weight: 400;
      font-family: "Poppins", sans-serif;
      color: white;
      padding: 10px 0 10px 0;
      margin-top: 5px;
    }
    .cardbutton {
      text-align: center;
      @media (max-width: 768px) {
        display: flex;
        padding-bottom: 25px;
      }
      .buttonwa {
        margin: 15px;
        font-size: 18px;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
        background-color: #ebbb4a;
        color: white;
        border: none;
        cursor: pointer;
        border-radius: 40px;
        padding: 12px 80px;
        @media (max-width: 768px) {
          padding: 12px 60px;
        }
        &:hover {
          overflow: hidden;
          color: #ebbb4a;
          background-color: white;
        }
      }
    }
  }
}
