.carouselfasc {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .container-carouselfasc {
    width: 80%;
    @media (max-width: 768px) {
      width: 98%;
    }
    .slick-slider {
      width: 100%;
      box-shadow: 0 0 10px 0;
      .slick-next::before,
      .slick-prev::before {
        font-family: "slick";
        font-size: 30px;
      }
      .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
      }
      .slick-dots {
        position: relative;
        bottom: 8px;
      }
      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 25px;
      }
      .slick-dots {
        li {
          margin: 0;
        }
      }
    }
  }
}
