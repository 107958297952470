.ctapage {
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.header {
  font-size: 45px;
  color: #33375e;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 35px;
  }
}
.clusterbutton {
  margin-bottom: 60px;
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .nerin {
    margin-left: 20px;
    margin-right: 20px;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
    .button1 {
      padding: 65px 120px;
      cursor: pointer;
      background-color: white;
      border-width: 0px;
      box-shadow: 0 0 10px 0 rgb(182, 182, 182);
      border-radius: 5px;
      font-size: 0px;
      font-family: "Rubik";
      background-image: url(./ruko.webp);
      background-size: cover;
      color: #bd9e2400;
      font-size: 16px;
      &:hover {
        font-size: 16px;
        padding: 65px 120px;
        background-image: none;
        transition: 0.5s;
        color: #bd9e24;
      }
    }
  }
  .khione {
    margin-right: 20px;
    margin-left: 20px;
    .button {
      padding: 65px 120px;
      cursor: pointer;
      background-color: white;
      border-width: 0px;
      box-shadow: 0 0 10px 0 rgb(182, 182, 182);
      border-radius: 5px;
      font-size: 0px;
      font-family: "Rubik";
      background-image: url(./apartment.webp);
      background-size: cover;
      color: #bd9e2400;
      font-size: 16px;
      &:hover {
        font-size: 16px;
        padding: 65px 120px;
        background-image: none;
        transition: 0.5s;
        color: #bd9e24;
      }
    }
  }
}
.buttonhome {
  width: 50%;
  display: flex;
  justify-content: center;
  gap: 20px;
  @media (max-width: 768px) {
    width: 90%;
  }
  .page {
    width: 100%;
    background-color: #33375e;
    color: white;
    padding: 12px 45px;
    border-radius: 5px;
    border-width: 3px;
    border-color: #33375e;
    &:hover {
      border-width: 3px;
      border-color: #33375e;
      color: #33375e;
      background-color: white;
      transition: 0.3s;
      cursor: pointer;
    }
  }
}
