.heroc {
  width: 100%;
  .container-heroc {
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
      margin-top: -100px;
      margin-bottom: -200px;
      @media (max-width: 768px) {
        margin-top: -50px;
        margin-bottom: -50px;
      }
    }
  }
}
