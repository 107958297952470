.aboutc {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  .container-aboutc {
    width: 80%;
    @media (max-width: 768px) {
      width: 98%;
    }
    .judul-aboutc {
      font-size: 35px;
      font-family: "Poppins";
      color: #33375e;
      font-weight: 600;
      @media (max-width: 768px) {
        font-size: 28px;
      }
    }
  }
}
