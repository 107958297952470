.produk {
  width: 100%;
  background-color: #ffa532;
  .container-produk {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .header-produk {
      width: 80%;
      @media (max-width: 768px) {
        width: 98%;
      }
      .judul-produk {
        font-size: 55px;
        font-weight: 600;
        color: white;
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.463);
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .carousel-produk {
        box-shadow: 0 0 10px 0;
        .slick-slider {
          width: 100%;
          .slick-next::before,
          .slick-prev::before {
            font-family: "slick";
            font-size: 30px;
          }
          .slick-track {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
          }
          .slick-dots {
            position: absolute; /* Make dots absolute for positioning within slides */
            bottom: 10px; /* Adjust vertical positioning */
            left: 50%; /* Center dots horizontally */
            transform: translateX(-50%); /* Center dots precisely */
            z-index: 1; /* Ensure dots are above image content */
          }
          .slick-next {
            right: 25px;
          }
          .slick-prev {
            z-index: 1;
            left: 30px;
          }
          .slick-dots {
            li {
              margin: 0;
            }
          }
        }
      }
    }
    .container-cardproduk {
      margin-top: 25px;
      width: 80%;
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 30px;
      justify-content: center;
      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }
    }
  }
  .button {
    margin-top: 2%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 30px;
      justify-content: center;
      align-items: center;
    }
    .buttonwa2 {
      .whatsapp {
        width: 100%;
        background-color: #33375e;
        color: white;
        padding: 12px 25px;
        border-radius: 5px;
        border-width: 3px;
        border-color: #33375e;
        &:hover {
          border-width: 3px;
          border-color: #33375e;
          color: #33375e;
          background-color: white;
          transition: 0.3s;
          cursor: pointer;
        }
      }
    }
    .buttonwa3 {
      .page {
        width: 100%;
        background-color: #33375e;
        color: white;
        padding: 12px 45px;
        border-radius: 5px;
        border-width: 3px;
        border-color: #33375e;
        &:hover {
          border-width: 3px;
          border-color: #33375e;
          color: #33375e;
          background-color: white;
          transition: 0.3s;
          cursor: pointer;
        }
      }
    }
  }
  .dividerb {
    width: 100%;
    background-color: #ffa532;
    height: 100px;
    overflow: hidden;
    position: relative;
    &:before {
      content: "";
      font-family: "shape divider from ShapeDividers.com";
      position: absolute;
      bottom: -1px;
      left: -1px;
      right: -1px;
      top: -1px;
      z-index: 3;
      pointer-events: none;
      background-repeat: no-repeat;
      background-size: 100% 90px;
      background-position: 50% 100%;
      background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M35.28 1.67c-3.07-.55-9.27.41-16.15 0-6.87-.4-13.74-.58-19.13.1v.4h35.28z" fill="%23ffffff"/><path d="M35.28 1.16c-3.17-.8-7.3.4-10.04.56-2.76.17-9.25-1.47-12.68-1.3-3.42.16-4.64.84-7.04.86C3.12 1.31 0 .4 0 .4v1.77h35.28z" opacity=".5" fill="%23ffffff"/><path d="M35.28.31c-2.57.84-7.68.3-11.8.43-4.1.12-6.85.61-9.57.28C11.18.69 8.3-.16 5.3.02 2.3.22.57.85 0 .87v1.2h35.28z" opacity=".5" fill="%23ffffff"/></svg>');
    }

    @media (min-width: 768px) {
      .shapedividers_com-1322::before {
        background-size: 100% 90px;
        background-position: 50% 100%;
      }
    }

    @media (min-width: 1025px) {
      .shapedividers_com-1322::before {
        bottom: -0.1vw;
        left: -0.1vw;
        right: -0.1vw;
        top: -0.1vw;
        background-size: 100% 145px;
        background-position: 50% 100%;
      }
    }
    @media (min-width: 2100px) {
      .shapedividers_com-1322::before {
        background-size: 100% calc(2vw + 145px);
      }
    }
  }
}
