@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

.header-newlaunch {
  font-size: 55px;
  color: #33375e;
  font-family: "Poppins";
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 45px;
  }
}
.belova {
  width: 100%;
  .header-belova {
    width: 100%;
    .judulb {
      font-size: 67px;
      font-family: "Poppins";
      font-weight: 700;
      color: #e5ca9b;
      text-shadow: 2px 0px 2px rgba(0, 0, 0, 0.558);
      @media (max-width: 768px) {
        font-size: 45px;
      }
    }
    .subjudulb {
      font-size: 37px;
      font-family: "Poppins";
      font-weight: 700;
      color: #e5ca9b;
      text-shadow: 2px 0px 2px rgba(0, 0, 0, 0.558);
      @media (max-width: 768px) {
        font-size: 25px;
      }
    }
  }
}
.overlay {
  text-decoration: none;
  color: black;
}
.container-cardb {
  margin-top: 3%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .hardsell-b {
    width: 50%;
    text-align: right;
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
    }
    img {
      width: 70%;
      border-radius: 10px;
      box-shadow: 0 0 10px 0;
      @media (max-width: 768px) {
        width: 90%;
      }
    }
  }
  .card {
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    @media (max-width: 768px) {
      width: 95%;
    }
    .buttonwa1 {
      width: 35%;
      margin-top: -10px;
      @media (max-width: 768px) {
        width: 100%;
      }
      .whatsapp {
        background-color: #ffa532;
        color: white;
        padding: 12px 65px;
        border-radius: 5px;
        border-width: 3px;
        border-color: #ffa532;
        &:hover {
          border-width: 3px;
          border-color: #ffa532;
          color: #ffa532;
          background-color: white;
          transition: 0.3s;
          cursor: pointer;
        }
        @media (max-width: 768px) {
          padding: 15px 75px;
        }
      }
    }
    .button {
      width: 50%;
      display: flex;
      gap: 10px;
      @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
        align-items: center;
      }
      .buttonwa2 {
        .whatsapp {
          width: 100%;
          background-color: #ffa532;
          color: white;
          padding: 12px 25px;
          border-radius: 5px;
          border-width: 3px;
          border-color: #ffa532;
          &:hover {
            border-width: 3px;
            border-color: #ffa532;
            color: #ffa532;
            background-color: white;
            transition: 0.3s;
            cursor: pointer;
          }
        }
      }
      .buttonwa3 {
        .page {
          width: 100%;
          background-color: #ffa532;
          color: white;
          padding: 12px 45px;
          border-radius: 5px;
          border-width: 3px;
          border-color: #ffa532;
          &:hover {
            border-width: 3px;
            border-color: #ffa532;
            color: #ffa532;
            background-color: white;
            transition: 0.3s;
            cursor: pointer;
          }
        }
      }
    }

    .belova {
      width: 45%;
      display: flex;
      flex-direction: column;
      border-style: solid;
      border-width: 2px;
      border-color: rgb(0, 0, 0);
      border-radius: 15px;
      font-family: "Poppins";
      &:hover {
        box-shadow: 0px 0px 5px 0px rgba(58, 58, 58, 0.43);
        cursor: pointer;
      }
      @media (max-width: 768px) {
        width: 100%;
      }

      .atas {
        display: flex;
        align-items: center;
        font-weight: 600;

        .promos {
          width: 50%;
          padding: 10px;
          font-size: 25px;
          @media (max-width: 768px) {
            font-size: 25px;
            padding: 0px;
          }
        }
        .detail {
          width: 50%;
          padding: 10px;
          font-weight: 600;

          .start {
            font-size: 16px;
            margin-bottom: -5px;
          }
          .angka {
            font-size: 50px;
          }
          .ket {
            font-size: 18px;
            margin-top: -10px;
          }
        }
      }
      .bawah {
        display: flex;
        border-width: 3px 0 3px 0;
        border-style: solid;
        border-radius: 10px;
        border-color: #ffa532;
        margin-top: 5px;
        margin-bottom: 10px;
        .logos1 {
          width: 50%;
          padding-top: 15px;
          padding-bottom: 15px;
          img {
            width: 70%;
          }
        }
        .logos2 {
          width: 50%;
          padding-top: 15px;
          padding-bottom: 15px;
          img {
            width: 70%;
          }
        }
        .logos3 {
          width: 100%;
          padding-top: 10px;
          padding-bottom: 10px;
          img {
            width: 50%;
          }
        }
      }
    }
  }
}
.tresor {
  width: 100%;
  margin-top: 3%;
  .header-tresor {
    width: 100%;
    .judult {
      font-size: 49px;
      font-family: "Poppins";
      font-weight: 500;
      color: #33375e;
      text-shadow: 2px 0px 2px rgba(0, 0, 0, 0.558);
      @media (max-width: 768px) {
        font-size: 45px;
      }
    }
    .subjudult {
      font-size: 38px;
      font-family: "Poppins";
      font-weight: 300;
      font-style: italic;
      color: #393939;
      text-shadow: 2px 0px 2px rgba(0, 0, 0, 0.558);
      @media (max-width: 768px) {
        font-size: 25px;
      }
    }
  }

  .container-cardt {
    margin-top: 3%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    .hardsell-t {
      width: 50%;
      text-align: right;
      @media (max-width: 768px) {
        width: 100%;
        text-align: center;
      }
      img {
        width: 70%;
        border-radius: 10px;
        box-shadow: 0 0 10px 0;
        @media (max-width: 768px) {
          width: 90%;
        }
      }
    }
    .card {
      width: 50%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 30px;
      @media (max-width: 768px) {
        width: 95%;
      }
      .button {
        width: 50%;
        display: flex;
        gap: 10px;
        @media (max-width: 768px) {
          width: 100%;
          justify-content: center;
          align-items: center;
        }
        .buttonwa2 {
          .whatsapp {
            width: 100%;
            background-color: #ffa532;
            color: white;
            padding: 12px 25px;
            border-radius: 5px;
            border-width: 3px;
            border-color: #ffa532;
            &:hover {
              border-width: 3px;
              border-color: #ffa532;
              color: #ffa532;
              background-color: white;
              transition: 0.3s;
              cursor: pointer;
            }
          }
        }
        .buttonwa3 {
          .page {
            width: 100%;
            background-color: #ffa532;
            color: white;
            padding: 12px 45px;
            border-radius: 5px;
            border-width: 3px;
            border-color: #ffa532;
            &:hover {
              border-width: 3px;
              border-color: #ffa532;
              color: #ffa532;
              background-color: white;
              transition: 0.3s;
              cursor: pointer;
            }
          }
        }
      }

      .tresor1 {
        width: 45%;
        display: flex;
        flex-direction: column;
        border-style: solid;
        border-width: 2px;
        border-color: rgb(0, 0, 0);
        border-radius: 15px;
        font-family: "Poppins";
        &:hover {
          box-shadow: 0px 0px 5px 0px rgba(58, 58, 58, 0.43);
          cursor: pointer;
        }
        @media (max-width: 768px) {
          width: 100%;
        }

        .atas {
          display: flex;
          align-items: center;
          font-weight: 600;

          .promos {
            width: 50%;
            padding: 10px;
            font-size: 25px;
            @media (max-width: 768px) {
              font-size: 25px;
              padding: 0px;
            }
          }
          .detail {
            width: 50%;
            padding: 10px;
            font-weight: 600;

            .start {
              font-size: 16px;
              margin-bottom: -5px;
            }
            .angka {
              font-size: 50px;
            }
            .ket {
              font-size: 18px;
              margin-top: -10px;
            }
          }
        }
        .bawah {
          display: flex;
          border-width: 3px 0 3px 0;
          border-style: solid;
          border-radius: 10px;
          border-color: #ffa532;
          margin-top: 5px;
          margin-bottom: 10px;
          .logos3 {
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
