@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

.container-promo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
  .quote {
    width: 100%;
    margin-top: 3%;
    padding: 10px 0px;
    background-color: #2e2f3e;
    font-size: 55px;
    font-weight: 600;
    color: white;
    @media (max-width: 768px) {
      font-size: 35px;
      width: 98%;
    }
  }
  .promo {
    margin-top: 30px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end; /* Added justify-content */
    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: -15px;
    }
    .containergambar {
      order: 1;
      width: 70%;
      text-align: right;
      @media (max-width: 768px) {
        margin-top: 25px;
        order: 1;
        width: 100%;
        text-align: center;
      }

      .penawarangambar {
        width: 80%;
        height: auto;
        align-self: flex-end;
        box-shadow: 0 0 10px 0;
        border-radius: 5px;
        @media (max-width: 768px) {
          width: 90%;
          height: 100%;
        }
      }
    }

    .containercontent {
      order: 2;
      width: 50%;
      margin-left: 100px;
      text-align: left;
      justify-content: center;
      display: flex;
      flex-direction: column;
      @media (max-width: 768px) {
        margin-left: 25px;
        width: 90%;
        order: 2;
        margin-top: 30px;
      }
    }
    .judul-promo {
      width: 80%;
      font-weight: 700;
      font-size: 42px;
      line-height: 1em;
      color: #33375e;
      font-family: "Poppins";
      @media (max-width: 768px) {
        width: 100%;
        font-size: 25px;
        margin-bottom: 17px;
      }
    }
    .disclaimer {
      margin-top: 25px;
      font-size: 13px;
    }
    .pointpenawaran {
      margin-top: 10px;
      flex-direction: row;
      @media (max-width: 768px) {
        width: 100%;
      }
      .penawaranpoin {
        margin-top: 0px;
        color: #272727;
        font-weight: 500;
        font-size: 25px;
        font-family: "Poppins";
        line-height: 1.5em;
        @media (max-width: 768px) {
          width: 100%;
          font-size: 24px;
        }
      }
    }
    .button-wa-promo {
      padding-top: 15px;
      padding-bottom: 10px;
      .whatsapp {
        background-color: #ffa532;
        color: white;
        padding: 12px 35px;
        border-radius: 5px;
        border-width: 3px;
        border-color: #ffa532;
        &:hover {
          border-width: 3px;
          border-color: #ffa532;
          color: #ffa532;
          background-color: white;
          transition: 0.3s;
          cursor: pointer;
        }
      }
    }
  }
}
